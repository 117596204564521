<template>
	<div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<BHLoading :show="loading" />
		<a-modal :visible="preview" :footer="null" @cancel="preview = false" @close="preview = false">
			<h3>Previewing Lots ({{ previewList.length }} of {{ previewCount }})</h3>
			<a-card v-for="(item, itemI) in previewList" :key="itemI">
				<ul>
					<template v-for="([key, value]) in Object.entries(item)">
						<li :key="key + 'item'" v-if="typeof value === 'string'">
							{{ key }}:
							<template v-if="typeof value === 'string'">
								<strong v-if="value && value.trim()">{{ value }}</strong>
								<em v-else class="text-med-gray">Empty</em>
							</template>
						</li>
					</template>
				</ul>
			</a-card>
		</a-modal>
		<a-card>
			<!-- Import Lots -->
			<a-tabs class="dataTab" default-active-key="1" @change="changeTab">
				<a-tab-pane key="1" tab="Import from CSV">
					<div v-if="parsedResults == null" class="w-full"
						style="text-align: center; margin-left: auto; margin-right: auto;">
						<div v-if="checking && stats.processing && stats.progress < 100">
							<p class="mt-3">Importing Lots</p>
							<span v-if="stats.eta">ETA : {{ stats.eta }} </span>
							<div>
								<a-progress :percent="stats.progress" status="active"
									:format="() => `${stats.count}/${stats.total}  (${stats.progress}%)`" />
							</div>
							<div v-if="stats.count === 0" class="dF aC jSA">
								<a-alert class="mt-3" message="Your process has been placed in queue will process shortly."
									type="info" show-icon style="width: fit-content;">
								</a-alert>
							</div>
							<a-button v-else class="mt-3" type="danger" @click="cancelImport">
								Stop Import
							</a-button>
						</div>
						<div v-else-if="checking">
							<h3>Checking processing...</h3>
						</div>
						<div v-else>
							<p class="mt-3">Choose file to get started </p>
							<div style="text-align:center">
								<a-button @click="$store.commit('MEDIA_SELECT', { callback: onFileUpload, type: '.csv' })"
									class="mt-2" size="large" type="primary">BROWSE</a-button>
							</div>
							<div class="dF aC jSA">
								<a-alert class="mt-3" type="warning" show-icon style="width: fit-content;">
									<template slot="message">
										Bildhive has a maximum upload of <strong style="color: red">15,000</strong> records
										at a time.
									</template>
								</a-alert>
							</div>
							<div class="dF aC jSA">
								<a-alert class="mt-3" type="info" show-icon style="width: fit-content;">
									<template slot="message">
										Download a <strong class="link" @click="downloadFile('sample')"> SAMPLE FILE </strong> containing instructions and a <strong class="link" @click="downloadFile('template')">TEMPLATE FILE</strong> with all the headings that can be mapped to Bildhive fields.
									</template>
								</a-alert>
							</div>
						</div>
					</div>
					<div v-if="parsedResults !== null" class="mx-3">
						<a-row class="mb-3" :gutter="16">
							<a-col :span="12" class="dF aC"><strong>CSV Headings</strong></a-col>
							<a-col :span="12" class="dF aC"><strong>Fields</strong></a-col>
						</a-row>
						<div v-for="(result, resultI) in parsedResults[0]" :key="resultI">
							<a-row class="mb-3" :gutter="16">
								<a-col :span="12" class="dF aC">
									<div :class="fields[resultI] ? '' : ''">
										{{ result }} <a-icon v-if="fields[resultI] && fields[resultI] !== 'ignore'"
											class="ml-2 text-primary" type="check-circle" />

									</div>
								</a-col>
								<a-col :span="12" class="dF aC">
									<a-select aria-placeholder="Ignore" placeholder="Ignore"
										@change="(e) => selectField(e, resultI)" style="width:100%" allowClear>
										<a-select-opt-group>
											<span slot="label"><a-icon type="user" /> Default Fields</span>
											<a-select-option v-for="field in lotFields" :key="field.value"
												:label="field.label" :value="field.value">{{
													field.label }}</a-select-option>
										</a-select-opt-group>
										<a-select-opt-group>
											<span slot="label"><a-icon type="profile" /> Custom Fields</span>
											<a-select-option v-for="field in customFields" :key="field.id"
												:label="field.name" :value="field.id">{{ field.name
												}}</a-select-option>
										</a-select-opt-group>
									</a-select>
								</a-col>
							</a-row>
						</div>

						<div class="mt-4">
							<p class="text-danger mb-0" v-if="error.lotError">Lot # field must be selected to
								continue.</p>
							<p class="text-danger" v-if="error.duplicateError">You have duplicate fields selected.</p>
						</div>
						<div class="dF aC jSB mt-5">
							<div>
								<a-button size="large" type="secondary" @click="cancel">CANCEL</a-button>
							</div>
							<div class="dF aC" style="gap: 20px">
								<a-tooltip overlayClassName="change-tooltip-color" title="Preview the mapping of the fields">
									<a-button icon="eye" @click="previewModal" size="large">Preview</a-button>
								</a-tooltip>
								<a-button @click="importLots" size="large" type="primary">IMPORT</a-button>
							</div>

						</div>
					</div>
				</a-tab-pane>

				<!-- Export lots -->
				<a-tab-pane key="2" tab="Export Data">
					<a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="exportRecords">
						<template slot="action">
							<div class="dF" style="color:var(--orange)">
								<div class="dF aC" style="cursor: pointer;" @click="exportLots">
									<a-icon type="download" class="mr-3" style="font-size:20px" />
									<div style="line-height: 20px;">CSV</div>
								</div>
							</div>
						</template>
					</a-table>
				</a-tab-pane>

				<!-- History of lots import and export -->
				<a-tab-pane key="3" tab="History">
					<a-table :rowKey="(e) => e.id" class="white-table" :columns="historyColumns" :data-source="historyData" :scroll="{ x: 1100 }">
						<div slot="fileName" slot-scope="obj">
							<div v-if="obj.data && obj.data.fileName">{{ obj.data.fileName }}</div>
							<div v-else>{{ `${instance.name}.csv` }}</div>
						</div>
						<div slot="actionBy" slot-scope="obj">
							<div>{{ getName(obj.user) }}</div>
						</div>
						<div slot="action" slot-scope="obj">
							{{ obj.data && obj.data.action || '' }}
						</div>
						<div slot="createdAt" slot-scope="obj">
							<div>{{ moment(obj.createdAt).format('lll') }}</div>
						</div>
						<div slot="status" slot-scope="obj">
							<div v-if="obj.data" class="px-2 py-1"
								style="color:#FFF; border-radius:4px; max-width:100px; text-align: center;"
								:style="obj.data.status == 'success' ? 'background-color:#29a845' : 'background-color:#dd3645'">
								{{ obj.data.status.charAt(0).toUpperCase() + obj.data.status.slice(1) }}</div>
						</div>
						<div slot="reason" slot-scope="obj">
							<div>{{ obj.data && obj.data.error || '' }}</div>
						</div>
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</a-card>
	</div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import moment from "moment";
import { downloadURL } from 'bh-mod'

export default {
	components: {
		BHLoading
	},
	data() {
		return {
			loading: false,
			importTrigger: false,
			checking: true,
			checkTimeout: null,
			importProgress: null,
			activeTab: 1,
			previewCount: 0,
			previewList: [],
			preview: false,
			fileToSend: {
				url: null,
				file: null
			},
			parsedResults: null,
			fields: [],
			error: {},
			columns: [
				{
					title: 'Name',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: 'Action',
					key: 'action',
					scopedSlots: { customRender: 'action' },
				},
			],
			exportRecords: [
				{ name: "Lots", type: "lots", id: 1 },
			],
			lotFields: [
				{ label: "Ignore", value: "ignore" },
				{ label: 'Block #', value: 'block' },
				{ label: 'City', value: 'city' },
				{ label: 'Deactivate architectural rules', value: 'deactivateArchitecturalRules' },
				{ label: 'Garage Side', value: 'garageSide' },
				{ label: 'Is Published', value: 'published' },
				{ label: 'Lot #', value: 'name' },
				{ label: 'Lot Front', value: 'front' },
				{ label: 'Lot Frontage', value: 'size' },
				{ label: 'Lot Left', value: 'left' },
				{ label: 'Lot Left Attached', value: 'attachedLeft' },
				{ label: 'Lot Right', value: 'right' },
				{ label: 'Lot Right Attached', value: 'attachedRight' },
				{ label: 'Model Type', value: 'modelType' },
				{ label: 'Risers at Front', value: 'frontRiser' },
				{ label: 'Risers at Garage', value: 'garageRiser' },
				{ label: 'Risers at Rear', value: 'rearRiser' },
				{ label: 'Secondary Street Name', value: 'street2' },
				{ label: 'Secondary Street Number', value: 'streetNumber2' },
				{ label: 'Secondary Zip/Postal Code', value: 'postal2' },
				{ label: 'Short Legal Description', value: 'legalDescription' },
				{ label: 'Standing Inventory Lot (inventory/available)', value: 'status' },
				{ label: 'State/Province', value: 'region' },
				{ label: 'Street Name', value: 'street' },
				{ label: 'Street Number', value: 'streetNumber' },
				{ label: 'Tags', value: 'tags' },
				{ label: 'Tentative/Firm Close Date', value: 'closingDate' },
				{ label: 'Zip/Postal Code', value: 'postal' }
			],
			historyData: [],
			historyColumns: [
				{
					title: 'File Name',
					scopedSlots: { customRender: 'fileName' },
					key: 'fileName',
					sorter: (a, b) => {
						const aa = a.data && a.data.fileName || this.instance.name;
						const bb = b.data && b.data.fileName || this.instance.name;

						return aa < bb ? -1 : aa > bb ? 1 : 0
					}
				},
				{
					title: 'Action By',
					scopedSlots: { customRender: 'actionBy' },
					key: 'actionBy',
					sorter: (a, b) => {
						const aa = this.getName(a.user);
						const bb = this.getName(b.user);

						return aa < bb ? -1 : aa > bb ? 1 : 0
					}
				},
				{
					title: 'Action',
					scopedSlots: { customRender: 'action' },
					key: 'action',
					sorter: (a, b) => {
						const aa = a.data && a.data.action || '';
						const bb = b.data && b.data.action || '';

						return aa < bb ? -1 : aa > bb ? 1 : 0
					}
				},
				{
					title: 'Date',
					scopedSlots: { customRender: 'createdAt' },
					key: 'createdAt',
					sorter: (a, b) =>
						moment(a.createdAt).format("x") -
						moment(b.createdAt).format("x"),
				},
				{
					title: 'Status',
					scopedSlots: { customRender: 'status' },
					key: 'status',
					sorter: (a, b) => {
						const aa = a.data && a.data.status || '';
						const bb = b.data && b.data.status || '';

						return aa < bb ? -1 : aa > bb ? 1 : 0
					}
				},
				{
					title: 'Reason',
					scopedSlots: { customRender: 'reason' },
					key: 'reason',
					sorter: (a, b) => {
						const aa = a.data && a.data.error || '';
						const bb = b.data && b.data.error || '';

						return aa < bb ? -1 : aa > bb ? 1 : 0
					}
				},
			]
		}
	},
	computed: {
		instance() {
			return this.$store.state.instance;
		},

		fieldLookup() {
			let fieldLookup = {}
			this.lotFields.forEach(({ label, value }) => {
				fieldLookup[value] = label
			})
			return fieldLookup
		},

		stats() {
			let stats = this.importProgress && this.importProgress.progress;
			if (!stats || typeof stats !== 'string' || stats.split(':').length !== 2 || isNaN(stats.split(':')[0]) || isNaN(stats.split(':')[1])) {
				return {
					processing: false
				}
			}

			let [count, total] = stats.split(':');
			count = parseInt(count);
			total = parseInt(total);
			return {
				processing: true,
				count,
				total,
				progress: Math.round((count / total) * 100),
				eta: this.importProgress && this.importProgress.eta || ''
			}
		},

		cFieldLookup() {
			let cFieldLookup = {}
			Object.values(this.customFields).forEach(({ name, id }) => {
				cFieldLookup[id] = name
			})
			return cFieldLookup
		},

		customFields() {
			return Object.values(this.$store.state.siteplan.customFields)
		},
	},
	watch: {
		checking: {
			immediate: true,
			handler(val) {
				if (val) {
					this.intervalCheck()
				} else {
					this.importProgress = null
					clearInterval(this.checkTimeout)
				}
			}
		},
	},
	methods: {
		moment,

		changeTab(tab) {
			this.activeTab = tab
			if (tab === '3') {
				this.fetchHistoryData()
			}
		},

		async exportLots() {
			if (this.$p < 40) {
				return this.$message.error(
					`You do not have permission for the export Lots`
				);
			}
			this.loading = true;

			try {
				const { data } = await this.$api.post(`lots/:instance/export`)

				let blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
				let a = window.document.createElement("a");
				a.href = window.URL.createObjectURL(blob, {
					type: "text/csv",
				});
				a.download = `${this.instance.name}-${Date.now()}.csv`;

				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);

				return this.$notification['success']({
					message: 'Export Complete',
					description: `Your Lots have been exported successfully.\n${new Date()}`,
					duration: 4
				});
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `Error while exporting Lots. Please try again`))
				}
			} finally {
				this.loading = false;
			}
		},

		async importLots() {
			if (this.hasError()) {
				return this.$message.error('There are errors in your submission')
			}

			this.loading = true;

			try {
				await this.$api.post(`lots/:instance/import`, { fields: this.fields, url: this.fileToSend.url, cFields: this.cFieldLookup })
				setTimeout(() => {
					this.checking = true;
					this.importTrigger = true;
				}, 2000)
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error while importing lots. Please try again'))
				}
			} finally {
				setTimeout(() => {
					this.loading = false;
					this.parsedResults = null
				}, 2000)
			}
		},

		callProgress() {
			return new Promise((resolve) => {
				this.$api.get(`/lots/:instance/import-progress/lot`)
					.then(({ data }) => {
						const progress = data && data.progress
						if (!progress || typeof progress !== 'string' || progress.split(':').length !== 2 || isNaN(progress.split(':')[0]) || isNaN(progress.split(':')[1])) {
							if ((this.importProgress && this.importProgress.progress) || this.importTrigger) {
								this.$router.go();
							}
							return resolve(false)
						}
						this.importProgress = data;
						return resolve(true)
					})
					.catch(() => {
						resolve(false)
					})
			})

		},
		async intervalCheck() {
			let result = await this.callProgress()
			if (!result) {
				this.checking = false;
			}
			this.checkTimeout = setInterval(async () => {
				result = await this.callProgress()
				if (!result) {
					this.checking = false;
				}
			}, 4000);
		},

		onFileUpload(file) {
			this.fileToSend = {
				url: null,
				file: null
			}
			this.fields = [];
			this.error = {}
			const validFile = (file.type && file.type === 'application/vnd.ms-excel') || (file.type && file.type.includes('csv')) || (file.ext && file.ext.includes('.csv'))

			if (!validFile) {
				return this.$message.error('You can only upload a CSV file!');
			}
			if (file.ext && file.url) this.fileToSend.url = file.url
			else this.fileToSend.file = file

			const isLt2M = file.size / 1024 / 1024 < 10;

			if (!isLt2M) {
				return this.$message.error('CSV must be smaller than 10MB!');
			}
			if (validFile && isLt2M) {
				if (file.status !== 'uploading') {
					self.loading = true;
					this.parseFile(file)
				}
			}

			return false
		},

		parseFile(file) {
			let self = this
			if (file.url) {
				this.$papa.parse(file.url, {
					download: true,
					complete: function (results) {
						self.parsedResults = self.parseResults(results.data)
						self.loading = false;
						self.fields = []
					}
				})
			} else {
				this.$papa.parse(file, {
					download: true,
					complete: function (results) {
						self.parsedResults = self.parseResults(results.data)
						self.loading = false;
						self.fields = []
					}
				})
			}
		},

		parseResults(array) {
			let headers = array[0]
			if (headers && headers.length) {
				let headerArray = []
				array[0] = headers.forEach((h) => {
					h = h.trim()
					if (headerArray.includes(h)) {

						let copied = 1
						let headerName = h + ` (${copied})`

						while (headerArray.includes(headerName)) {
							copied++
							headerName = h + ` (${copied})`
						}

						return headerArray.push(headerName)

					}
					return headerArray.push(h)
				})
				array[0] = headerArray
			}
			return array
		},

		previewModal() {
			let validFields = this.fields.filter(x => x && x !== 'ignore')
			if (validFields.length === 0) return this.$message.error('You do not have any fields mapped. Please do so before previewing')
			let list = this.createLots()
			this.previewCount = list.length
			list = list.splice(0, 3)

			let self = this;
			this.previewList = list.map(each => {
				let obj = {}
				Object.entries(each).forEach(([key, value]) => {
					let fieldLabel = self.fieldLookup[key] || self.cFieldLookup[key]
					if (fieldLabel) {
						obj[fieldLabel] = value
					}
				})
				return obj
			})

			if (!this.previewList.length) return this.$message.error('There was an error previewing. Please make sure you have the floor number and unit number field mapped correctly.')
			this.preview = true
		},

		createLots() {
			let [header, ...theRest] = this.parsedResults
			let lots = []

			theRest.forEach(lotFields => {
				let obj = {}

				this.fields.forEach((field, fieldI) => {
					if (field && field !== 'ignore') {
						obj[this.fields[fieldI]] = lotFields[fieldI]
					}
				})
				lots.push(obj)
			})

			lots.forEach((unit) => {
				delete unit.ignore
				delete unit.undefined
			})

			return lots
		},

		hasError() {
			let validFields = this.fields.filter(x => x && x !== 'ignore')
			let uniqueFields = Array.from(new Set(validFields))

			let duplicateError = validFields.length !== uniqueFields.length
			let lotError = !validFields.includes('name')
			this.error = {
				duplicateError,
				lotError
			}
			return duplicateError || lotError;
		},

		selectField(item, index) {
			let fields = this.fields
			fields[index] = item
			this.fields = fields
		},

		cancel() {
			this.parsedResults = null
			this.fields = []
		},

		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			);
		},

		async fetchHistoryData() {
			try {
				const { data } = await this.$api.get(`/timelines/:instance?type=lot`)
				this.historyData = data
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `Error while fetching import export log history.`))
				}
			}
		},

		getName(actionBy) {
			let name = ''
			if (!actionBy) {
				return name
			}
			if (actionBy.firstName) {
				name = actionBy.firstName
			}
			if (actionBy.lastName) {
				if (actionBy.firstName) name = name + ' ' + actionBy.lastName
				else name = actionBy.lastName
			}
			else name = actionBy.email
			return name
		},

		cancelImport() {
			if (this.$p < 40) {
				return this.$message.error('You do not have permission to stop import process.')
			}
			let self = this;
			this.$confirm({
				title: "Stop Import Process",
				content: (h) => <div>Do you want stop current import process?</div>,
				okText: "STOP",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.$api.post(`/lots/:instance/cancel-import/lot`).then(({ data }) => {
						if (data && data.success) {
							self.$message.success('Import process stopped successfully.')
						} else {
							self.$message.warn('Unable to stop import process. Either import process is not running or it is completed. Please check and try again!')
						}
					}).catch((err) => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `Error while stopping current import process. Please try again!`))
						}
					});
				},
				onCancel() {
					console.log("Cancel");
				},
			});
		},

		downloadFile(type) {
			const urls = {
				sample: {
					name: 'SPC-SAMPLE file to Import Lots.xlsx',
					link: 'https://docs.google.com/spreadsheets/d/1rW6A_ylBmZ8JtGBTNxkGBWwJvsDwZ-ereXqWyG8aaXI/export?format=xlsx&gid=1792328399#gid=1792328399'
				},
				template: {
					name: 'SPC-TEMPLATE to Import Lots.csv',
					link: 'https://docs.google.com/spreadsheets/d/1NjTIKdoATAdxkknAw3XNKveN_0O88MuaVt-xcblp0bw/export?format=csv&gid=1661424629#gid=1661424629'
				}
			};

			downloadURL(urls[type].link, urls[type].name);
		}
	},

	beforeDestroy() {
		this.importProgress = null
		clearInterval(this.checkTimeout)
	},
};
</script>

<style lang="scss">
.dataTab .ant-tabs-nav {
	font-size: 16px;

	.ant-tabs-tab {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.dataTab .ant-tabs-bar {
	margin: 0 0 25px 0;
}

.dataTab .ant-tabs-top-bar {
	border-bottom: none !important;
}
</style>

<style lang="scss" scoped>
.link {
	color: var(--primary);
	cursor: pointer;
}
</style>
